import React from "react";
import Raven from "raven-js";
import { isDevelopment } from "./Env";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      errMsg: error.message,
      hasError: true,
      isTest: isDevelopment() || window.location.hostname === "test.xinya.me",
    };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    Raven.captureBreadcrumb({
      message: "react component error",
      category: "error boundary",
      data: {
        error: error,
        info: info,
      },
    });
    Raven.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.state.isTest ? (
        <div style={{ backgroundColor: "red", fontSize: 18, color: "white" }}>
          {`组件错误：${this.state.errMsg}`}
        </div>
      ) : (
        ""
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
