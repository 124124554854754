/* istanbul ignore file */

import addMonths from "date-fns/add_months";
import formatTime from "date-fns/format";

/**
 * usage like: 两个日期的天数差值
 * 日期格式(以下三种都支持)：
 *  1. "2020-01-22T23:59:59.000+08:00"
 *  2. "2020-01-22"
 *  3. "2020.01.22"
 * 如：DateMinus("小日期", "大日期") => 1.86（单位：天）
 */
function DateMinus(system_date, end_date) {
  // system_date: 小日期  end_date: 大日期
  var systemDate = new Date(system_date);
  var endDate = new Date(end_date);
  var days = endDate.getTime() - systemDate.getTime();
  var day = Number(days / (1000 * 60 * 60 * 24)).toFixed(2);
  return day;
}

// time format
function formatAudioTime(seconds) {
  if (!seconds) {
    return "00:00";
  }
  const m = Math.floor(seconds / 60);
  const s = Math.floor(seconds % 60);
  return `${m < 10 ? "0" + (m < 0 ? 0 : m) : m}:${
    s < 10 ? "0" + (s < 0 ? 0 : s) : s
  }`;
}

/**
 * usage like:
 *  "2019-02-28T11:55:27.894+08:00" into "2019-02-28"
 */

function formatDate(date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function chineseYearMonthDayDate(date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const YMD = `${year}年${month}月${day}日`;
  return YMD;
}

// 返回：年月日 时：分
function formatDateYMDHM(time) {
  const d = new Date(time);
  const year = d.getFullYear();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let hours = "" + d.getHours();
  let minutes = "" + d.getMinutes();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (hours.length < 2) {
    hours = "0" + hours;
  }
  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }

  return [year, month, day].join(".") + " " + [hours, minutes].join(":");
}

/**
 * 返回: 年.月.日
 * usage like:
 * "2019-02-28T11:55:27.894+08:00" into "2019.02.28"
 */

function formatDotDate(date) {
  var d = new Date(date);
  var month = "" + (d.getMonth() + 1);
  var day = "" + d.getDate();
  var year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(".");
}
/**
 * 推荐此用法，简介
 * 返回: 年.月.日
 * usage like:
 * "2019-02-28T11:55:27.894+08:00" into "2019.02.28 18:00"
 */
const formatTimeStampYMDHM = (start_time, end_time) => {
  const startTime = formatTime(start_time, "YYYY.MM.DD HH:mm");
  const endTime = formatTime(end_time, "YYYY.MM.DD HH:mm");
  const timeStamp = `${startTime} - ${endTime}`;
  return timeStamp;
};

/**
 * 返回: 时:分
 * usage like:
 * "2019-02-28T11:55:27.894+08:00" into "11:55"
 */

function formatHourMinute(date) {
  var d = new Date(date);
  var hours = "" + d.getHours();
  var minutes = "" + d.getMinutes();

  if (hours.length < 2) {
    hours = "0" + hours;
  }
  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }

  return [hours, minutes].join(":");
}

/**
 * 返回: 年.月.日 时:分
 * @param date
 * @returns {string}
 */
function formatDotFullTime(date) {
  return formatDotDate(date) + " " + formatHourMinute(date);
}

function chineseMonthDayDate(time) {
  /**
   * usage like:
   * "2019-02-01" into "2月1日"
   * "2019-12-10" into "12月10日"
   * "2019-12-11" into "12月11日"
   */
  const first_month_zero = time.slice(5, 7)[0];

  const first_day_zero = time.slice(8, 10)[0];

  const showMonth = first_month_zero == 0 ? time.slice(6, 7) : time.slice(5, 7);
  const showDay = first_day_zero == 0 ? time.slice(9, 10) : time.slice(8, 10);

  const switchChineseDate = showMonth + "月" + showDay + "日";
  return switchChineseDate;
}

/**
 * 从当天开始的一周
 * @param {*} date new Date()
 * 返回，如：['2019/10/1', '2019/10/2',....,'2019/10/7']
 */
function getCurrentDateNextSevenDays(date) {
  const dateArray = [];
  let dateTemp;
  const flag = 1;
  for (let i = 0; i < 7; i++) {
    dateTemp = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    dateArray.push(dateTemp);
    date.setDate(date.getDate() + flag);
  }
  return dateArray;
}

/*
 * usage like:
 * "2019-02-01" into "2月1日"
 * "2019-12-10" into "12月10日"
 * "2019-12-11" into "12月11日"
 */
function convertMonthDay(date) {
  if (date === null || date === "") {
    return "";
  }
  var currentDate = date;
  if (typeof date === "string") {
    currentDate = new Date(date);
  }
  if (
    currentDate &&
    typeof currentDate === "object" &&
    "getDay" in currentDate &&
    "getMonth" in currentDate
  ) {
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    return `${year}.${month}.${day}`;
  }
}

function addMonth(date, months) {
  var date = addMonths(date, months);
  return date;
}

function format(date, formatString) {
  var date = formatTime(date, formatString);
  return date;
}

function DateCountDown(date, ref_date, date_formats, time_units) {
  // Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
  date_formats = date_formats || {
    past: [
      { ceiling: 60, text: "$seconds秒前" },
      { ceiling: 3600, text: "$minutes分钟前" },
      { ceiling: 86400, text: "$hours小时前" },
      { ceiling: 2629744, text: "$days天前" },
      { ceiling: 31556926, text: "$months个月前" },
      { ceiling: null, text: "$years年前" },
    ],
    future: [
      { ceiling: 60, text: "$seconds秒前" },
      { ceiling: 3600, text: "$minutes分钟前" },
      { ceiling: 86400, text: "$hours小时前" },
      { ceiling: 2629744, text: "$days天前" },
      { ceiling: 31556926, text: "$months个月前" },
      { ceiling: null, text: "$years年前" },
    ],
  };
  // Time units must be be ordered largest -> smallest
  time_units = time_units || [
    [31556926, "years"],
    [2629744, "months"],
    [86400, "days"],
    [3600, "hours"],
    [60, "minutes"],
    [1, "seconds"],
  ];

  date = new Date(date);
  ref_date = ref_date ? new Date(ref_date) : new Date();
  var seconds_difference = (ref_date - date) / 1000;

  var tense = "past";
  if (seconds_difference < 0) {
    tense = "future";
    seconds_difference = 0 - seconds_difference;
  }

  function get_format() {
    for (var i = 0; i < date_formats[tense].length; i++) {
      if (
        date_formats[tense][i].ceiling == null ||
        seconds_difference <= date_formats[tense][i].ceiling
      ) {
        return date_formats[tense][i];
      }
    }
    return null;
  }

  function get_time_breakdown() {
    var seconds = seconds_difference;
    var breakdown = {};
    for (var i = 0; i < time_units.length; i++) {
      var occurences_of_unit = Math.floor(seconds / time_units[i][0]);
      seconds = seconds - time_units[i][0] * occurences_of_unit;
      breakdown[time_units[i][1]] = occurences_of_unit;
    }
    return breakdown;
  }

  function render_date(date_format) {
    var breakdown = get_time_breakdown();
    var time_ago_text = date_format.text.replace(/\$(\w+)/g, function () {
      return breakdown[arguments[1]];
    });
    return depluralize_time_ago_text(time_ago_text, breakdown);
  }

  function depluralize_time_ago_text(time_ago_text, breakdown) {
    for (var i in breakdown) {
      if (breakdown[i] == 1) {
        var regexp = new RegExp("\\b" + i + "\\b");
        time_ago_text = time_ago_text.replace(regexp, function () {
          return arguments[0].replace(/s\b/g, "");
        });
      }
    }
    return time_ago_text;
  }

  return render_date(get_format());
}

export {
  DateMinus,
  formatAudioTime,
  formatDateYMDHM,
  formatDate,
  formatDotDate,
  formatHourMinute,
  DateCountDown,
  chineseYearMonthDayDate,
  chineseMonthDayDate,
  getCurrentDateNextSevenDays,
  convertMonthDay,
  addMonth,
  format,
  formatDotFullTime,
  formatTimeStampYMDHM,
};
