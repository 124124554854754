/* istanbul ignore file */
import axios from "axios";

const metaElement = document.querySelector('meta[name="csrf-token"]');

let csrfToken;
if (metaElement && metaElement instanceof window.HTMLMetaElement) {
  csrfToken = metaElement.content;
}
// console.log(metaElement, "ssssssssssssssssssssssssss", csrfToken);
const csrfHeaders = {
  "X-CSRF-Token": csrfToken,
  "X-Requested-With": "XMLHttpRequest",
};

const request = axios;

const getAxiosErrorText = (error, text = "提交答案") => {
  let errText = `${text}出错`;
  const statusCode = error?.response?.status;
  if (statusCode === 500 || statusCode === 502) {
    errText = "服务器暂时不可用，请稍后重试";
  }
  if (error?.message?.includes?.("timeout")) {
    errText = `${text}超时，请检查您的网络后重试`;
  }
  if (error.message === "Network Error" || !navigator.onLine) {
    errText = `${text}出错，请检查您的网络后重试`;
  }
  return errText;
};

export { request, csrfHeaders, getAxiosErrorText };
