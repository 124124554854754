/* eslint-disable react/prop-types */
import React from "react";
import PageLoading from "./loader/PageLoading.jsx";

const LazyPage = (loader) => {
  const comp = (props) => {
    const Comp = React.lazy(loader);
    return (
      <React.Suspense fallback={<PageLoading text={"页面加载中..."} />}>
        <Comp {...props} />
      </React.Suspense>
    );
  };
  comp.displayName = "LazyPage";
  return comp;
};

export const Posters = LazyPage(() =>
  import(/* webpackChunkName: 'posters' */ "./posters/Posters.jsx")
);

export const Setting = LazyPage(() =>
  import(/* webpackChunkName: 'user-setting' */ "./user-setting/Setting.jsx")
);

export const InvitationPromotion = LazyPage(() =>
  import(
    /* webpackChunkName: 'invitation-promotion' */ "./invitation-promotion/InvitationPromotion.jsx"
  )
);

export const InvitationRewards = LazyPage(() =>
  import(
    /* webpackChunkName: 'invitation-rewards' */ "./invitation-rewards/InvitationRewards.jsx"
  )
);

export const Promotion = LazyPage(() =>
  import(/* webpackChunkName: 'promotion' */ "./promotion/Promotion.jsx")
);

export const LotteryPromotion = LazyPage(() =>
  import(
    /* webpackChunkName: 'lottery-promotion' */ "./lottery-promotion/LotteryPromotion.jsx"
  )
);

export const UserPromotionRecords = LazyPage(() =>
  import(
    /* webpackChunkName: 'user-promotion-records' */ "./user-promotion-records/UserPromotionRecords.jsx"
  )
);

export const RecentExperiences = LazyPage(() =>
  import(
    /* webpackChunkName: 'recent-experiences' */ "./recent-experiences/RecentExperiences.jsx"
  )
);

export const Page502 = LazyPage(() =>
  import(/* webpackChunkName: 'page-502' */ "./page-502/Page502.jsx")
);
