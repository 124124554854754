import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Mask = (props) => {
  const { transparent, className, ...others } = props;
  const clz = classNames(
    {
      "xinyaui-mask": !transparent,
      "xinyaui-mask_transparent": transparent,
    },
    className
  );
  return <div className={clz} {...others}></div>;
};

Mask.propTypes = {
  transparent: PropTypes.bool,
};
Mask.defaultProps = {
  transparent: false,
};

export default memo(Mask);
