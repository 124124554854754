import React from "react";
import PropTypes from "prop-types";
import { $for } from "core-js";
export class TabPane extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return this.props.children;
  }
}

TabPane.propTypes = {
  children: PropTypes.node,
  tab: PropTypes.any,
  styles: PropTypes.string,
  active: PropTypes.string,
};

export class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.defaultActiveIndex || 0,
    };
    this.tabs = [];
    this.ref = React.createRef();
  }

  defaultAct = (child, e) => {
    const dataId = e.currentTarget.getAttribute("data-id");
    this.setState(() => ({
      activeIndex: dataId,
    }));
    if (this.props.animatedTabTitle) {
      this.locater(dataId);
    }
    if (child.props.onClick && typeof child.props.onClick === "function") {
      child.props.onClick(e);
    }
    if (typeof this.props.defaultAction === "function") {
      this.props.defaultAction(e, dataId);
    }
  };

  loopTabs = () => {
    let contents = [];

    React.Children.forEach(this.props.children, (child, index) => {
      let tab = "";
      // 提取tab title
      if (this.tabs[index]) {
        tab = React.cloneElement(
          this.tabs[index],
          {
            className: `_tab_group_container ${child.props.styles} ${
              this.state.activeIndex == index ? child.props.activeStyle : ""
            }`,
            "data-id": index,
            onClick: this.defaultAct.bind(null, child),
          },
          child.props.tab
        );
        this.tabs.splice(index, 1, tab);
      } else {
        tab = React.createElement(
          child.props.as ?? "div",
          {
            className: `_tab_group_container ${child.props.styles} ${
              this.state.activeIndex == index ? child.props.activeStyle : ""
            }`,
            "data-id": index,
            onClick: this.defaultAct.bind(null, child),
            key: `${this.tabs.length}`,
          },
          child.props.tab
        );
        this.tabs.push(tab);
      }

      // 提取tab对应的内容
      if (this.state.activeIndex != index) {
        return;
      }
      if (typeof child.props.children === "string") {
        contents.push(child.props.children);
        return;
      }
      if (child.type) {
        contents.push(React.cloneElement(child.props.children, { key: index }));
      } else {
        return child.props.children;
      }
    });
    return { tabs: this.tabs, contents };
  };

  locater = (activeIndex) => {
    const containers = this.ref.current.getElementsByClassName(
      "_tab_group_container"
    );
    const galleryGroupContainer = this.ref.current;
    const containerArr = [].slice.call(containers, 0);
    let activeItemWidth = 0;
    let normalItemsWidth = 0;
    for (const [index, container] of containerArr.entries()) {
      if (index == activeIndex) {
        activeItemWidth = container.offsetWidth;
        const scroll =
          normalItemsWidth +
          activeItemWidth / 2 -
          galleryGroupContainer.offsetWidth / 2;

        if (this.props.noAnimatedScroll) {
          $(this.ref.current).scrollLeft(scroll);
        } else {
          setTimeout(
            () => $(this.ref.current).animate({ scrollLeft: scroll }, 500),
            100
          );
        }

        break;
      }
      const style =
        container.currentStyle || window.getComputedStyle(container);
      const marginLeft = style.marginLeft.replace("px", "");
      if (marginLeft > 0) {
        normalItemsWidth =
          parseInt(normalItemsWidth) +
          parseInt(container.offsetWidth) +
          parseInt(marginLeft);
      } else {
        normalItemsWidth =
          parseInt(normalItemsWidth) + parseInt(container.offsetWidth);
      }
    }
  };

  componentDidMount() {
    if (this.props.animatedTabTitle) {
      const { activeIndex } = this.state;
      this.locater(activeIndex);
    }
  }

  render() {
    const { tabs, contents } = this.loopTabs();
    return [
      React.createElement(
        this.props.as ?? "div",
        {
          className: this.props.styles,
          key: "tab",
          id: "_tabs_group_container",
          ref: this.ref,
        },
        tabs
      ),
      contents,
    ];
  }
}
Tabs.propTypes = {
  children: PropTypes.node,
  defaultAction: PropTypes.func,
  styles: PropTypes.string,
  defaultActiveIndex: PropTypes.number,
  animatedTabTitle: PropTypes.bool,
};
Tabs.defaultProps = {
  animatedTabTitle: false,
};
