import Raven from "raven-js";
class CreateStore {
  constructor(states, reducer) {
    this.states = states;
    this.reducer = reducer;
    this.listener = [];
    this.isDispatching = false;
  }

  subscribe = (fn) => {
    if (this.isDispatching) {
      console.error(
        "Can not subscribe checkUpdate useSelector while store is under emitting"
      );
      return;
    }
    this.listener.push(fn);
  };

  emit = () => {
    this.isDispatching = true;
    if (this.listener.length) {
      try {
        this.listener.forEach((l) => l());
      } catch (e) {
        console.error(e);
        Raven.captureException(e);
      }
    }
    this.isDispatching = false;
  };

  getStates = () => {
    return this.states;
  };

  setStates = (states) => {
    this.states = states;
  };

  destroy = () => {
    console.log("store destroy");
    if (!this.listener.length) {
      return;
    }
    console.log("store destroied");
    this.listener = [];
    this.states = null;
  };
}

export default CreateStore;
