/* istanbul ignore file */

import React from "react";
import ReactDOM from "react-dom";
import ReactXinyaUi from "./XinyaUi/index";
import "./XinyaUi/xinya_ui.scss";
import "./Dialog.scss";

class Dialog extends React.Component {
  render() {
    return <ReactXinyaUi.Dialog type="ios" show={true} {...this.props} />;
  }
}

class DialogProxy {
  constructor(component) {
    this.node = document.createElement("div");
    document.body.appendChild(this.node);
    ReactDOM.render(component, this.node);
  }

  close() {
    ReactDOM.unmountComponentAtNode(this.node);
  }
}

const DialogManager = {
  open(component) {
    const proxy = new DialogProxy(component);
    return proxy;
  },

  showMsg(options) {
    let dialog;
    const { msg, btnLabel = "确认", onClick = () => dialog.close() } = options;
    const buttons = [
      {
        label: btnLabel,
        onClick: () => {
          if (typeof onClick == "function") {
            onClick();
          }
        },
      },
    ];
    return (dialog = DialogManager.open(
      <Dialog buttons={buttons}>{msg}</Dialog>
    ));
  },
};

export { Dialog, DialogManager };
