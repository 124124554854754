/* eslint-disable no-global-assign */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback } from "react";
import Raven from "raven-js";
import queryString from "query-string";
import { formatDotDate } from "../../utils/formatAudioTime";
import Image from "../../utils/Image";
import iconSeries from "new_course_home/icon_series.png";
import {
  request as axios,
  getAxiosErrorText,
  csrfHeaders,
} from "../../utils/request";

import { useSelector } from "../../utils/react-global-state";

import { ToastManager } from "../../utils/Toast";
import { awaitWrapper, showDialog, onNavigate } from "../../utils/helpers";
import iconAge from "new_course_home/age_7.png";
import "./BindMobile.scss";

const BindMobile = memo(({ ...props }) => {
  // console.log("OwnedCourses_props: ", props);
  const { mobileRequired, edit_mobile_url } = props;

  // 无课程无手机号
  const renderMobileRequiredContent = () => {
    return (
      <div className="no_activity_brief">
        <div className="no_recommend_text">
          <div className="text">
            <p>请登录购课手机号，</p>
            <p>查看已报名的课程</p>
          </div>
          <div
            className="choose_grade_button"
            onClick={(e) => onNavigate(e, edit_mobile_url)}
          >
            登录
          </div>
        </div>
        <div className="icon_age">
          <Image className="lazyload lazyloading" url={iconAge} />
        </div>
      </div>
    );
  };

  const renderCourseTitle = () => {
    return (
      <div className="title">
        <img className="lazyload lazyloading" data-src={iconSeries} />
        我的课程
      </div>
    );
  };
  return (
    <div className="owned_courses_container color_th">
      {renderCourseTitle()}
      {renderMobileRequiredContent()}
    </div>
  );
});

export default BindMobile;
