/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { defaultFunc } from "../../components/utils/helpers.js";
import PropTypes from "prop-types";
import "./PageLoading.scss";

const PageLoading = ({ text, onRetryLoad, error }) => {
  return (
    <div className="comp_spinner">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
      {error && (
        <div className="error" onClick={onRetryLoad}>
          {"页面加载出错，请重试"}
        </div>
      )}
      {text && <p className="desc">{text}</p>}
    </div>
  );
};

PageLoading.propTypes = {
  text: PropTypes.string,
  onRetryLoad: PropTypes.func,
  error: PropTypes.string,
};

PageLoading.defaultProps = {
  onRetryLoad: defaultFunc,
};

const StyledLoadingAnimation = styled.div`
  .lds-spinner {
    color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 70px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 26px;
    left: 39px;
    width: 3px;
    height: 7px;
    border-radius: 32%;
    background: rgba(0, 0, 0, 0.9);
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
  }
  /* .lds-spinner div:nth-child(9) {
    transform: rotate(360deg);
    animation-delay: -0.3s;
  } */
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const LoadingHitContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoadingHitText = styled.p`
  text-align: center;
  color: #999999;
  font-size: 14px;
`;

export const RetryLoadingButton = styled.div`
  color: #fff;
  font-size: 12px;
  min-width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgba(106, 211, 59, 0.5);
  border-radius: 15px;
  white-space: nowrap;
  background: #49c114;
  width: 100px;
  margin: 0 auto;
  margin-top: 25px;
`;

export const LoadingHit = ({ style }) => {
  return (
    <LoadingHitContainer style={style || {}}>
      <StyledLoadingAnimation>
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </StyledLoadingAnimation>
    </LoadingHitContainer>
  );
};

export default PageLoading;
