/* istanbul ignore file */

import Raven from "raven-js";
import axios from "axios";
// import { request, csrfHeaders } from "./request";
export function isDevelopment() {
  const env = document
    .querySelector("meta[name='data:env']")
    ?.getAttribute?.("content");
  return env === "development";
}

export function isProduction() {
  const env = document
    .querySelector("meta[name='data:env']")
    ?.getAttribute?.("content");
  return env === "production";
}

export function GA(eventCategory, eventOperate, eventTag) {
  try {
    console.log(eventCategory, eventOperate, eventTag);
    if (isProduction()) {
      if (typeof ga === "function") {
        ga("send", "event", `${eventCategory}`, `${eventOperate}`, eventTag);
      }
    }
    if (isDevelopment()) {
      console.log(
        "development ga log: ",
        "send",
        "event",
        `${eventCategory}`,
        `${eventOperate}`,
        eventTag
      );
    }
  } catch (e) {
    console.error(e);
    Raven.captureException(e);
  }
}

/**
 *
 * @param {*事情类别} eventCategory
 * @param {*事件操作} eventOperate
 * @param {* 时间} eventTag
 * @param {*} callback
 */
export function GA_new(
  eventCategory,
  eventOperate,
  eventTag,
  target_type,
  target_id,
  callback
) {
  let data = {
    target_id,
    operate: eventOperate,
    category: eventCategory,
    target_type,
    tag: eventTag,
  };
  // console.log("++++++++++++++++", target_type, target_id, data);
  const metaElement = document.querySelector('meta[name="csrf-token"]');

  let csrfToken;
  if (metaElement && metaElement instanceof window.HTMLMetaElement) {
    csrfToken = metaElement.content;
  }
  // console.log(metaElement, "ssssssssssssssssssssssssss", csrfToken);
  const csrfHeaders = {
    "X-CSRF-Token": csrfToken,
    "X-Requested-With": "XMLHttpRequest",
  };
  // callback && callback();
  // if (isProduction()) {
  return axios({
    url: "/user_interaction_tracks",
    data: data,
    method: "POST",
    headers: csrfHeaders,
  }).then((res) => {
    callback && callback();
    // console.log("11111111");
  });
  // }
  // try {
  //   console.log(eventCategory, eventOperate, eventTag);
  //   if (isProduction()) {
  //     if (typeof ga === "function") {
  //       ga("send", "event", `${eventCategory}`, `${eventOperate}`, eventTag);
  //     }
  //   }
  //   if (isDevelopment()) {
  //     console.log(
  //       "development ga log: ",
  //       "send",
  //       "event",
  //       `${eventCategory}`,
  //       `${eventOperate}`,
  //       eventTag
  //     );
  //   }
  // } catch (e) {
  //   console.error(e);
  //   Raven.captureException(e);
  // }
}
