import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./toptips.scss";

const Toptips = (props) => {
  const { className, type, children, show, ...others } = props;
  const cls = classNames({
    "xinyaui-toptips": true,
    [`xinyaui-toptips_${type}`]: true,
    [className]: className,
  });

  return show ? (
    <div className={cls} {...others}>
      {children}
    </div>
  ) : (
    ""
  );
};

Toptips.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
};

Toptips.defaultProps = {
  show: false,
  type: "default",
};

export default memo(Toptips);
