/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useMemo,
  lazy,
  Suspense,
  useRef,
  useEffect,
} from "react";
import Raven from "raven-js";
import ErrorBoundary from "../components/utils/ErrorBoundary";

// const SlideCarousel = LazyComp({
//   loader: {
//     Slider: () =>
//       import(
//         /* webpackChunkName: 'slide-carousel' */ "../../async-components/slide-carousel/SlideCarousel.jsx"
//       ),
//     data: (url) => {
//       console.log(url);
//       return new Promise((resolve) =>
//         setTimeout(
//           () =>
//             resolve([
//               {
//                 id: 142,
//                 photo_url:
//                   "https://upload.fireflybaby.cn/campaign/142/b45815e897815bb181434a8b5a25e87a.png",
//                 target_url: "https://xinya.me/promotion_activities/144",
//               },
//             ]),
//           2000
//         )
//       );
//     },
//   },
//   render: (loaded, props) => {
//     const Slider = loaded.Slider.default;
//     const data = loaded.data;
//     // console.log("lazy render");
//     return <Slider {...props} campaigns={data} />;
//   },
//   loading: ({ error, retry }) => (
//     <StyledSlideCarouselContainer onClick={error ? retry : () => {}}>
//       <LoadingHit text={error ? "加载出错啦，请点击重试" : "加载中..."} />
//     </StyledSlideCarouselContainer>
//   ),
// });

const solveLoader = async (loader, fetchUrl) => {
  if (typeof loader === "object" && loader) {
    const loaded = await Promise.all(
      Object.keys(loader).map((k) => {
        const p = loader[k];
        if (typeof p === "function")
          return Promise.resolve(p(fetchUrl).then((res) => ({ [k]: res })));
        else return Promise.resolve(null);
      })
    );
    const res = {};
    loaded.forEach((l) => {
      Object.keys(l).forEach((k) => (res[k] = l[k]));
    });
    return res;
  } else {
    return Promise.resolve(loader());
  }
};

const buildESComp = (res, loader, render, props) => {
  if (typeof loader === "object" && loader) {
    const c = render(res, props);
    const reBuiltComp = React.cloneElement(c);
    return {
      default: () => reBuiltComp,
    };
  } else {
    return res;
  }
};

const LoadingSuspense = ({ load, pastDelay, loading }) => {
  const raceLoading = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            default: () =>
              loading({
                error: null,
                loading: load,
                pastDelay: true,
              }),
          }),
        pastDelay || 300
      );
    });
  }, [load, loading, pastDelay]);

  const LoadingHit = useMemo(() => {
    return lazy(async () => {
      const fallBack = await raceLoading();
      return fallBack;
    });
  }, [raceLoading]);
  return (
    <Suspense fallback="">
      <LoadingHit />
    </Suspense>
  );
};
/**
 * loader: 需要异步加载的模块或api
 * loading: loading ui
 * render: 自定义的render 函数
 * pastDelay: pastDelay 避免loading态闪烁的阈值
 * isUseEffect: 整个异步加载是否在父组件渲染好后再加载，避免主线程执行太久
 */
export const LazyComp = ({
  loader,
  loading,
  render,
  pastDelay,
  isUseEffect = false,
}) => {
  const Loading = ({ error, load, retry }) =>
    loading({ error, loading: load, retry });
  let cache = null;
  const comp = ({ fetchUrl, ...props }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isAfterUseEffect, setIsAfterUseEffect] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const compRef = useRef();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [load, setLoading] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isRetry, setRetry] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const retry = useCallback(() => {
      console.log("user retry");
      Raven.captureBreadcrumb({
        message: "chunk load error",
        category: "user retry",
        data: {
          timestamp: Date.now(),
        },
      });
      Raven.captureMessage("user retry load chunk", { level: "info" });
      setRetry(true);
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const Lazy = useMemo(() => {
      if (compRef.current || cache) {
        // console.log(
        //   "from cache",
        //   `cache ${!!cache}`,
        //   `compRef.current ${!!compRef.current}`
        // );
        if (cache) return cache(props).default;
        return compRef.current.default;
      }
      return lazy(async () => {
        // console.log("lazy lazy");
        try {
          const res = await solveLoader(loader, fetchUrl);
          compRef.current = buildESComp(res, loader, render, props);
          // setLoading(false);
          setRetry(false);
          return compRef.current;
        } catch (e) {
          compRef.current = null;
          console.error("error", e);
          Raven.captureBreadcrumb({
            message: "chunk load error",
            category: "error",
            data: {
              msg: e.message,
              timestamp: Date.now(),
            },
          });
          Raven.captureException(e);
          // setLoading(false);
          setRetry(false);
          return {
            default: () => <Loading error={e} load={false} retry={retry} />,
          };
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRetry, retry]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (isUseEffect) {
        setIsAfterUseEffect(true);
      }
    }, []);
    return (
      <ErrorBoundary>
        {isUseEffect && !isAfterUseEffect ? (
          <Loading error={null} load={true} />
        ) : (
          <Suspense
            fallback={
              <LoadingSuspense
                load={true}
                pastDelay={pastDelay}
                loading={loading}
              />
            }
          >
            <Lazy {...props} />
          </Suspense>
        )}
      </ErrorBoundary>
    );
  };

  comp.preLoadAll = async (fetchUrl) => {
    try {
      // console.log("preLoadAll");
      const res = await solveLoader(loader, fetchUrl);
      cache = (props) => buildESComp(res, loader, render, props);
    } catch (e) {
      console.error(e);
    }
  };
  comp.displayName = "LazyComp";

  return comp;
};
