import React from "react";
import ReactXinyaUi from "./XinyaUi/index";
import "./XinyaUi/xinya_ui.scss";

import ComponentProxy from "../core/ComponentProxy";

class Toptips extends React.Component {
  render() {
    return <ReactXinyaUi.Toptips show={true} {...this.props} />;
  }
}

class ToptipsProxy extends ComponentProxy {
  constructor(component) {
    super();
    this.component = component;
  }

  createComponent() {
    return this.component;
  }
}

const ToptipsManager = {
  warn(content) {
    return ToptipsManager.show("warn", content);
  },

  primary(content) {
    return ToptipsManager.show("primary", content);
  },

  info(content) {
    return ToptipsManager.show("info", content);
  },

  show(type, content) {
    const proxy = new ToptipsProxy(<Toptips type={type}>{content}</Toptips>);
    proxy.open();
    setTimeout(() => proxy.cancel(), 2000);
    return proxy;
  },
};

export { Toptips, ToptipsManager };
