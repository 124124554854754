/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
// import arrowRight from "arrow-right.png";
import iconSync from "new_course_home/icon_sync.png";
import iconAge from "new_course_home/age_7.png";
import iconConfirm from "coupon/icon_select.png";
import { onNavigate } from "../../utils/helpers";
import Image from "../../utils/Image";
import { Tabs, TabPane } from "../../utils/Tabs/Tabs.js";
import "./RecommendCourses.scss";

// 通用课程模块
const CommonCourseModule = ({ act, handleCourseClick }) => {
  const scrollContainerRef = useRef();
  useLayoutEffect(() => {
    const container = scrollContainerRef.current;
    container.scrollLeft = 0;
    var parentHeight = scrollContainerRef.current.getBoundingClientRect()
      .height;
    // console.log(parentHeight);
    const childNodes = scrollContainerRef.current.childNodes;

    // console.log(childNodes);

    if (childNodes) {
      Array.from(childNodes).forEach((node) => {
        node.style.height = `${parentHeight}px`;
      });
    }
  }, []);
  useEffect(() => {
    const { position } = act;
    const container = scrollContainerRef.current;
    if (container) {
      // console.log(container.childNodes);
      const {
        width: containerWidth,
        left: containerLeft,
      } = container.getBoundingClientRect();
      const childNodes = container.childNodes;
      const activeNode = childNodes[position - 1];
      if (activeNode) {
        const { left, width } = activeNode.getBoundingClientRect();

        container.scrollLeft =
          left + width / 2 - containerWidth / 2 - containerLeft;
      } else {
        container.scrollLeft = containerWidth;
      }
    }
  }, []);
  return (
    <div className="activity_brief_box">
      <div
        className="common_title"
        // style={{ maxWidth: act.main_photo_url ? "50%" : "100%" }}
      >
        <div className="title">
          <span
            className="act_type"
            style={
              act.category_type === "主题推荐"
                ? { backgroundColor: "#00e6e6" }
                : {}
            }
          >
            {act.category_type}
          </span>
          <span className="act_name">{act.name}</span>
        </div>
        <p>{act.brief}</p>
      </div>

      <div className="scroll_container" ref={scrollContainerRef}>
        {!act.activities || !act.activities.length
          ? ""
          : act.activities.map((sub_act, idx) => (
              <div
                key={idx}
                className="activity_brief_content"
                data-url={`${sub_act.buy_url}&ref=home`}
                onClick={handleCourseClick}
              >
                <div className="left_thumbnail_common">
                  <Image
                    className="lazyload lazyloading"
                    url={sub_act.recommend_thumb_photo_url}
                  />
                </div>
                <div className="right_content_box">
                  <div className="content_box">
                    <div className="course_title">{sub_act.name}</div>
                    <div className="recomment_brief">
                      {sub_act.recommend_brief}
                    </div>
                  </div>
                  {sub_act.isShowLabels && (
                    <div className="labels">
                      {!!(
                        sub_act.lessonsOrCourseCount || sub_act.course_day_count
                      ) && (
                        <div className="label_left">
                          {!!sub_act.lessonsOrCourseCount && (
                            <>
                              <span className="lessons">
                                {`${sub_act.lessonsOrCourseCount}${
                                  sub_act.isPoetry ? "首诗" : "节课"
                                }`}
                              </span>
                            </>
                          )}

                          {!!(
                            sub_act.lessonsOrCourseCount &&
                            sub_act.course_day_count &&
                            !sub_act.is_user_study_package
                          ) && <span className="split">·</span>}

                          {!!sub_act.course_day_count &&
                            !sub_act.is_user_study_package && (
                              <span className="days">{`${sub_act.course_day_count}天`}</span>
                            )}
                        </div>
                      )}
                      {sub_act.suitable_grades &&
                        sub_act.suitable_grades.length > 0 && (
                          <div className="label_right">
                            <span className="suit">适合</span>
                            <span className="grades">
                              {sub_act.suitable_grades.join(" ")}
                            </span>
                          </div>
                        )}
                    </div>
                  )}
                  <div className="course_study_progress">
                    <div className="left_progress">
                      {sub_act?.sub_course_type == "thinking" ? (
                        ""
                      ) : (
                        <>
                          {`¥${sub_act.price}`}
                          <i className="text_progress">起</i>
                        </>
                      )}
                    </div>
                    {sub_act.bought_state === "bought" ? (
                      <div className="bought">
                        <Image
                          className="lazyload lazyloading"
                          url={iconConfirm}
                        />
                        {/* <img
                          className="lazyload lazyloading"
                          data-src={iconConfirm}
                        /> */}
                        已购买
                      </div>
                    ) : (
                      <div className="right_now_class">查看详情</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

// 课程推荐模块
export const RecommendCourseModule = ({
  openChooseGradeModalStatus,
  handleCourseClick,
  grades,
  recommendCourses,
  mobileRequired,
}) => {
  // 无推荐内容（选择年级）
  const noRecommendContent = () => {
    return (
      <div className="no_activity_brief">
        <div className="no_recommend_text">
          <div className="text">
            <p>请添加孩子年级信息，</p>
            <p>以便我们更好的为您推荐内容</p>
          </div>
          <div
            className="choose_grade_button"
            onClick={() => openChooseGradeModalStatus(true)}
          >
            选择年级
          </div>
        </div>
        <div className="icon_age">
          <Image className="lazyload lazyloading" url={iconAge} />
          {/* <img className="lazyload lazyloading" data-src={iconAge} /> */}
        </div>
      </div>
    );
  };
  // 有推荐内容
  const hasRecommendContent = () => {
    return recommendCourses.activities.map((sub_act, idx) => (
      <div
        key={idx}
        className="activity_brief_content"
        data-url={sub_act.alias_type ? `${sub_act.url}&ref=recommend_home` : ""}
        onClick={handleCourseClick}
      >
        <div className="left_thumbnail">
          <Image
            className="lazyload lazyloading"
            url={sub_act.recommend_thumb_photo_url}
          />
        </div>
        <div className="right_content_box">
          <div className="content_box_common">
            <div className="course_title">{sub_act.recommend_title}</div>
            <div className="recommend_hint">{sub_act.recommend_hint}</div>
          </div>
          <div className="course_study_progress">
            <div className="left_progress">
              {sub_act?.sub_course_type == "thinking" ? (
                ""
              ) : (
                <>
                  {`¥${sub_act.price}`}
                  <i className="text_progress">起</i>
                </>
              )}
            </div>
            <div className="right_now_class">查看详情</div>
          </div>
        </div>
      </div>
    ));
  };

  return (recommendCourses?.activities?.length > 0 || grades) &&
    !mobileRequired ? (
    <div className="comp_sub_activity_brief color_th" style={{ padding: 15 }}>
      <div className="activity_brief_box">
        <div className="recomend_title">
          <Image className="lazyload lazyloading" url={iconSync} />
          {/* <img className="lazyload lazyloading" data-src={iconSync} /> */}
          {recommendCourses.name}
        </div>
        {recommendCourses?.activities?.length > 0
          ? hasRecommendContent()
          : !mobileRequired && grades && noRecommendContent()}
      </div>
    </div>
  ) : (
    ""
  );
};

const StyledSubActivityBrief = styled.div`
  background-image: url(${({ url }) => url});
`;

const SubActivityBrief = ({ act }) => {
  // console.log("SubActivityBrief_props: ", props);

  const handleCourseClick = (e) => {
    const url = e.currentTarget.getAttribute("data-url");
    onNavigate(e, url);
  };

  return (
    <StyledSubActivityBrief
      className="comp_sub_activity_brief scroll_wrappers color_th"
      url={
        document.documentElement.classList.contains("webp")
          ? `${act.main_photo_url}?imageMogr2/format/webp`
          : act.main_photo_url
      }
    >
      <div className="activity_brief_box">
        <CommonCourseModule act={act} handleCourseClick={handleCourseClick} />
      </div>
    </StyledSubActivityBrief>
  );
};

export const RecommendCommonActivityTabs = ({ allActivityCourses }) => {
  const fakeTriggerTabClick = useRef(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const flag = useRef(false);
  const pageMounted = useRef();
  const tabClick = (e, activeTabIndex) => {
    // console.log("activeTabIndex: ", activeTabIndex);
    setSelectedTabIndex(activeTabIndex * 1);
    flag.current = true;
  };

  useLayoutEffect(() => {
    if (!pageMounted.current) {
      pageMounted.current = true;
      return;
    }

    if (!fakeTriggerTabClick.current && flag.current) {
      // eslint-disable-next-line no-unused-expressions
      document
        .getElementsByClassName("scroll_wrappers")
        [selectedTabIndex]?.scrollIntoView(true);

      window.scrollBy(0, -70);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    $(".sub_tabs").on("click", function (event) {
      if (event.originalEvent === undefined) {
        fakeTriggerTabClick.current = true;
      } else {
        fakeTriggerTabClick.current = false;
      }
    });
    const toScrollEles = document.getElementsByClassName("scroll_wrappers");
    const eleArr = Array.from(toScrollEles);
    const scroll = () => {
      eleArr.forEach((ele, index) => {
        const { top, height } = ele.getBoundingClientRect();
        // 下滑和上滑
        if (
          ((top > 0 && top <= 70) ||
            (top < 0 && height - Math.abs(top) >= 58)) &&
          !flag.current
        ) {
          $(".sub_tab_wrapper").eq(index).trigger("click");
          flag.current = false;
        }
      });
      flag.current = false;
      if (
        Math.ceil($(document).scrollTop()) >=
        $(document).height() - $(window).height()
      ) {
        console.log("已到底部");
        $(".sub_tab_wrapper")
          .eq(allActivityCourses.length - 1)
          .trigger("click");
      }
    };
    document.addEventListener("scroll", scroll);
  }, []);
  return (
    <div className="comp_sub_activity_tabs">
      <Tabs
        defaultAction={tabClick}
        styles="sub_tabs"
        defaultActiveIndex={selectedTabIndex}
        animatedTabTitle={true}
        noAnimatedScroll={true}
      >
        {allActivityCourses
          .map((act) => act.navigate_name)
          .map((item, index) => {
            return (
              <TabPane
                tab={
                  <div className="sub_tab" index={index}>
                    {item}
                  </div>
                }
                styles="sub_tab_wrapper"
                activeStyle="active"
                key={index}
              >
                {""}
              </TabPane>
            );
          })}
      </Tabs>

      <div className="mark_down_brief" id="tab_briefs">
        {allActivityCourses.map((act, index) => (
          <SubActivityBrief key={index} act={act} />
        ))}
      </div>
    </div>
  );
};
