import React, { useEffect, useState, useMemo } from "react";
import { expiredLocalStorage, showDialog } from "../utils/helpers.js";
import UserAgent from "../utils/UserAgent.js";
import PrivacyDialog from "./PrivacyDialog.jsx";

const withAppPrivacy = (Comp) => {
  const WithAppPrivacyModal = (props) => {
    const { setLocalStorage, getLocalStorage } = useMemo(
      () => expiredLocalStorage(),
      []
    );
    const [isShowAppPrivacyModal, setIsShowAppPrivacyModal] = useState(false);

    useEffect(() => {
      console.log("showPrivacy", getLocalStorage("showPrivacy"));
      if (
        !props.user &&
        !getLocalStorage("showPrivacy") &&
        UserAgent.isNativeClient()
      ) {
        setIsShowAppPrivacyModal(true);
        setLocalStorage("showPrivacy", "true", 30);
      }
      // setIsShowAppPrivacyModal(true);
    }, [getLocalStorage, props.user, setLocalStorage]);

    useEffect(() => {
      if (isShowAppPrivacyModal) {
        const dialogOptions = {
          text: <PrivacyDialog />,
          mainLabel: "确定",
          subLabel: "仅浏览",
        };
        showDialog.call(this, dialogOptions);
      }
    }, [isShowAppPrivacyModal]);

    return <Comp {...props} />;
  };

  return WithAppPrivacyModal;
};

export default withAppPrivacy;
