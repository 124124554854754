import Dialog from "./dialog/index";
import Toast from "./toast/index";
import Toptips from "./toptips/index";
import Mask from "./mask/index";
import Icon from "./icon/index";

export default {
  Dialog,
  Toast,
  Toptips,
  Mask,
  Icon,
};
