import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Icon = (props) => {
  const { value, size, className, primary, ...others } = props;
  const cls = classNames({
    ["xinyaui-icon-" + value]: value !== "loading",
    "xinyaui-icon_msg": size === "large" && !primary,
    "xinyaui-icon_msg-primary": size === "large" && primary,
    "xinyaui-loading": value === "loading",
    [className]: className,
  });

  return <i {...others} className={cls} />;
};

Icon.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
};
Icon.defaultProps = {
  value: "success",
  size: "small",
};

export default memo(Icon);
