import "lazysizes/plugins/include/ls.include";
import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/lazysizes";
import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes/plugins/fix-ios-sizes/fix-ios-sizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/bgset/ls.bgset";
import { isDevelopment } from "../components/utils/Env";
import Raven from "raven-js";

if (window) {
  const consoleLog = window.console.log;
  try {
    window.console.log = (function () {
      if (isDevelopment() || window.location.href.includes("admin")) {
        return consoleLog;
      }
      if (window.location.hash === "#test") {
        return consoleLog;
      }
      // if (window && window.location.host === "test.xinya.me") {
      //   return consoleLog;
      // }
      return () => {};
    })();
  } catch (e) {
    window.console.log = consoleLog;
    console.error(e);
  }
}

const environment = window.environment || "production";
if (environment === "production") {
  Raven.config(
    "https://85f168d784f84c8a8f19a38f37811df8@sentry.io/1261941",
    {}
  ).install();
  const user_name = window.user_name;
  const user_id = window.user_id;
  const userAgent = window.agent_str || "";

  if (user_id != null && user_id !== "null" && user_id !== "undefined") {
    Raven.setUserContext({
      id: user_id,
      username: user_name,
    });
    Raven.setTagsContext({
      login: "已登录",
      environment: environment,
      env_state: userAgent,
    });
  } else {
    Raven.setTagsContext({
      login: "未登录",
      environment: environment,
      env_state: userAgent,
    });
  }
  window.Raven = Raven;
  if (window.ravenErrors?.length) {
    window.ravenErrors.forEach((error) => {
      Raven.captureException(error);
    });
  }
}

const catchWxUndefined = () => {
  console.error("wx is undefined");
  Raven.captureException("wx is undefined");
};

window.wx = window.wx || {
  ready: catchWxUndefined,
  miniProgram: {
    getEnv: catchWxUndefined,
  },
  onVoiceRecordEnd: catchWxUndefined,
  startRecord: catchWxUndefined,
  stopRecord: catchWxUndefined,
  uploadVoice: catchWxUndefined,
  error: catchWxUndefined,
};

try {
  wx.error = function (res) {
    Raven.captureException(res);
  };
} catch (e) {
  Raven.captureException("wx on error issue");
}

// window.addEventListener("load", function () {
//   try {
//     const sc = document.getElementsByTagName("script");
//     sc.forEach((s) => {
//       if (s.src) {
//         const link = document.createElement("link");
//         link.as = "script";
//         link.href = s.src;
//         link.rel = "prefetch";
//         document.head.append(link);
//       }
//     });
//   } catch (e) {}
// });

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.expand = 0.4;
window.lazySizesConfig.loadMode = 1;
window.lazySizesConfig.requireJs = function (modules, cb) {
  window.require(modules, cb);
};
window.lazySizesConfig.customMedia = {
  "--small": "(max-width: 480px)",
  "--medium": "(max-width: 700px)",
  "--large": "(max-width: 1400px)",
};
