import React, { Component } from "react";
import { request, csrfHeaders } from "../request";
import { reloadPage } from "../helpers";
import { ToptipsManager } from "../Toptips";
import "./CityArea.scss";

export default class CityArea extends Component {
  constructor(props) {
    super(props);

    let defa_pro = -1;
    let defa_city = -1;

    let province = [];
    let cities = [];
    for (let item of this.props.provinces) {
      province.push({ id: item.id, name: item.name });
      cities.push(item.cities);
    }

    if (props.area) {
      defa_pro = province.findIndex(function (element) {
        return element.name === props.area.province.name;
      });

      defa_city = cities[defa_pro].findIndex(function (ele) {
        return ele.name === props.area.city.name;
      });
      // console.log("默认省/市",defa_pro)
      // console.log("默认城区", defa_city)
    }

    this.state = {
      overlayDisplay: "none",
      selectIndex: defa_pro, // 如果默认选择左列的省市 则显示-1
      selectSubIndex: defa_city, //如果默认选择右列中的城市区域 则显示-1
      selectProvinceId: this.props.area ? this.props.area.province.id : -1,
      selectSubCityId: this.props.area ? this.props.area.city.id : -1,
      province: province,
      cities: cities,
      selectCity: cities[defa_pro],
    };

    this.selectAreaClick = this.selectAreaClick.bind(this);
  }

  componentDidMount() {
    this.scrollAreaTop();

    if (this.props.giftCommodies) {
      // console.log("cityArea", this.state.selectIndex);
      // console.log("cityArea", this.state.selectSubIndex);
      this.props.getScrollAreaTop(
        this.state.selectIndex,
        this.state.selectSubIndex
      );
    }
  }

  scrollAreaTop = () => {
    let scrollEleTop = document.getElementById("province_list");
    let scrollSubEleTop = document.getElementById("sub_city_list");

    scrollEleTop.scrollTop = 40 * this.state.selectIndex;
    scrollSubEleTop.scrollTop = 40 * this.state.selectSubIndex;

    console.log(
      "top距离：" + scrollEleTop.scrollTop + "," + scrollSubEleTop.scrollTop
    );
  };

  // 弹框显示城市列表
  selectAreaClick = () => {
    this.setState({
      overlayDisplay: "block",
    });
  };

  // 关闭城市列表
  btnCloseClick = () => {
    // console.log("giftCommodies: ", this.props.giftCommodies);

    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择左列中的省市");
      return;
    } else if (this.state.selectSubIndex === -1) {
      ToptipsManager.warn("请选择右列中的城区");
      return;
    } else {
      if (this.props.giftCommodies) {
        // 填写地址相关逻辑
        let {
          selectProvinceId,
          selectSubCityId,
          selectValue,
          selectSubValue,
        } = this.state;
        // console.log('selectSubCityId 左侧省 id: ', selectSubCityId);
        // console.log('selectProvinceId 右侧城市 id: ', selectProvinceId);
        // console.log('city area 左侧省: ', selectValue);
        // console.log('city area 右侧城市: ', selectSubValue);

        // 首次进入页面, 不做操作, 关闭页面时, 如果有area值, 默认取area相关的值
        let defaSelectProvinceId;
        let defaSelectSubCityId;
        let defaSelectValue;
        let defaSelectSubValue;

        defaSelectProvinceId = selectProvinceId
          ? selectProvinceId
          : this.props.area && this.props.area.province.id;
        defaSelectSubCityId = selectSubCityId
          ? selectSubCityId
          : this.props.area && this.props.area.city.id;

        defaSelectValue = selectValue
          ? selectValue
          : this.props.area && this.props.area.province.name;
        defaSelectSubValue = selectSubValue
          ? selectSubValue
          : this.props.area && this.props.area.city.name;

        console.log("defaSelectProvinceId: ", defaSelectProvinceId);
        console.log("defaSelectSubCityId: ", defaSelectSubCityId);
        console.log("defaSelectValue: ", defaSelectValue);
        console.log("defaSelectSubValue: ", defaSelectSubValue);

        this.props.getAddressCityId(
          defaSelectProvinceId,
          defaSelectSubCityId,
          defaSelectValue,
          defaSelectSubValue
        );

        // 点击关闭选项弹框, 回调到上层组件
        this.props.getScrollAreaTop(
          this.state.selectIndex,
          this.state.selectSubIndex
        );

        this.props.getCloseDialog();
      } else {
        const url = this.props.user_url;
        let params = {
          province_id: parseInt(this.state.selectProvinceId),
          city_id: parseInt(this.state.selectSubCityId),
        };
        if (this.props.updateUserAddress) {
          this.props.updateUserAddress(
            this.state.selectSubValue || this.props.area.city.name
          );
        }
        this.setState({
          overlayDisplay: "none",
        });
        request({
          url: url,
          method: "PATCH",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            const data = resp.data;
            console.log(data);
            reloadPage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    this.props.displayPickerDialog();
  };

  // 点击相应的 省/市
  cityListClick = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const index = e.currentTarget.getAttribute("data-index");
    let value = e.currentTarget.getAttribute("data-value");
    const selectCity = this.state.cities[index];
    // console.log("选择省市后显示城市列表",selectCity)
    // console.log("右侧第一个城市",this.state.cities[index][0].name)

    // if (this.state.selectCity.length <= 0) {
    //   value = ''
    // }

    this.setState(
      {
        selectProvinceId: id,
        selectIndex: index,
        selectValue: value,
        selectCity: selectCity,
        selectSubIndex: -1, // 如果想默认选中第一个，则改为数字 0
        selectSubCityId: -1,
        // selectSubCityId: this.state.cities[index][0].id // 默认选中当前省市下的第一个市
      },
      () => {
        // console.log("--index--:" + this.state.selectIndex)
        // console.log("selectValue", this.state.selectValue)
        // console.log("selectCity", this.state.selectCity)
      }
    );
  };

  // 显示 区/市
  subCityClick = (e) => {
    const id = e.currentTarget.getAttribute("sub-data-id");
    const index = e.currentTarget.getAttribute("sub-data-index");
    const value = e.currentTarget.getAttribute("sub-data-value");
    this.setState(
      {
        selectSubCityId: id,
        selectSubIndex: index,
        selectSubValue: value,
      },
      () => {
        // console.log("selectSubValue", this.state.selectSubValue)
      }
    );
  };

  render() {
    return (
      <div
        className="comp_city_area"
        style={{
          display: this.props.giftCommodies
            ? this.props.propCommodiesState == false
              ? "none"
              : "block"
            : null,
        }}
      >
        <div id="picker" className="picker_select">
          <div className="overlay"></div>
          <div id="pick_mask" className="pick_mask">
            <h3>选择居住地</h3>
            <div className="close_button_right" onClick={this.btnCloseClick}>
              确定
            </div>
            <ul style={{ float: "left" }} id="province_list">
              {this.state.province &&
                this.state.province.map((province, index) => (
                  <li
                    key={index}
                    data-id={province.id}
                    data-index={index}
                    data-value={province.name}
                    onClick={this.cityListClick}
                    className={
                      this.state.selectIndex == index ? "highlight" : null
                    }
                  >
                    {province.name}
                  </li>
                ))}
            </ul>
            <ul
              id="sub_city_list"
              className="sub_city_value"
              style={{ float: "right" }}
            >
              {this.state.selectCity &&
                this.state.selectCity.map((city, idx) => (
                  <li
                    key={city.id}
                    sub-data-index={idx}
                    sub-data-id={city.id}
                    sub-data-value={city.name}
                    onClick={this.subCityClick}
                    className={
                      this.state.selectSubIndex == idx ? "highlight" : null
                    }
                  >
                    {city.name}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
