/* istanbul ignore file */
export default class UserAgent {
  static currentType() {
    if (document.querySelector("meta[name='data:user_agent']")) {
      const json = JSON.parse(
        document
          .querySelector("meta[name='data:user_agent']")
          .getAttribute("content")
      );
      const { UNKNOWN, IOS, ANDROID, WECHAT, MINIPROGRAM } = json.types;
      const curType = json.cur_type;
      return curType;
    } else {
      return 0;
    }
  }

  static isIOS() {
    let curType = UserAgent.currentType();
    return curType == 1;
  }

  static isAndroid() {
    let curType = UserAgent.currentType();
    return curType == 2;
  }

  static isNativeClient() {
    return UserAgent.isIOS() || UserAgent.isAndroid();
  }

  static isWechat() {
    let curType = UserAgent.currentType();
    return curType == 3;
  }

  static isWebBrowser() {
    return UserAgent.isUnknown();
  }

  static isMiniProgram() {
    let curType = UserAgent.currentType();
    return curType == 4;
  }

  static isUnknown() {
    let curType = UserAgent.currentType();
    return curType == 0;
  }
}
