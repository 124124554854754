/* eslint-disable react/prop-types */
import React, { memo } from "react";
import icon_settings from "coupon/icon_set.png";
import icon_ticket from "coupon/icon_ticket.png";
import defaultUserAvatar from "icon_avatar.png";
import "./UserInfo.scss";

const UserInfo = memo(({ ...props }) => {
  const { user, baby, area, ecoupons, city, displaySubPickerDialog } = props;

  // 点击居住地事件，唤醒城市列表选择框
  const selectCityAreaClick = () => {
    displaySubPickerDialog();
  };

  const renderUserInfoWrapper = () => {
    return (
      <div className="user_info_wrapper">
        <div className="user_avatar">
          <img
            data-src={user.photo_url || defaultUserAvatar}
            alt=""
            className="lazyload"
          />
        </div>
        {user.name ? (
          <div className="text_info">
            <p>{user.name}</p>
            <div className="baby_city_info">
              {baby && baby.nickname ? (
                <div className="baby_info">
                  {<span className="nick-name span-inb">{baby.nickname}</span>}
                  {baby.grade && <span className="span-inb">{baby.grade}</span>}
                </div>
              ) : (
                <a className="add_baby_info" href={baby.new_baby_path}>
                  添加孩子信息&nbsp;&nbsp;
                </a>
              )}
              <div className={area ? "city_info_area" : "add_city_info"}>
                {area === undefined ? (
                  city ? (
                    `${city || ""}`
                  ) : (
                    <div className="area_text" onClick={selectCityAreaClick}>
                      选择居住地
                    </div>
                  )
                ) : area.city ? (
                  area.city.name
                ) : (
                  area.province.name
                )}
              </div>
            </div>
          </div>
        ) : (
          <a
            href="javascript:void(0)"
            data-url={user.setting_path}
            className="to_edit_user_info"
            onClick={props.onMiniProgramClick}
          >
            完善个人信息
          </a>
        )}
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <a
        className="user_setting_box"
        href="javascript:void(0)"
        data-url={user.setting_path}
        onClick={props.onMiniProgramClick}
      >
        <div className="setting_box">
          <img src={icon_settings} alt="" />
          <div className="user_setting">设置</div>
        </div>
      </a>
    );
  };
  const renderCoupons = () => {
    return (
      <a
        className="user_coupon"
        href="javascript:void(0)"
        data-url={ecoupons.url}
        onClick={props.onMiniProgramClick}
      >
        <div
          className={`setting_box ${ecoupons.count > 0 ? "coupon_box" : ""}`}
          badge={ecoupons.count > 0 ? ecoupons.count : null}
        >
          <img src={icon_ticket} alt="" />
          <div className="user_setting">优惠券</div>
        </div>
      </a>
    );
  };

  return (
    <div className="user_info_container">
      {renderUserInfoWrapper()}
      {renderSettings()}
      {renderCoupons()}
    </div>
  );
});

export default UserInfo;
