/* eslint-disable react/prop-types */
import React, { memo } from "react";
import CountDownTime from "../../utils/CountDown";
import "./BuyStatusTips.scss";

const BuyStatusTips = memo(({ ...props }) => {
  const { userOngoingActivities, systemTime } = props;

  return (
    <div className="buy_status_tips_container">
      {userOngoingActivities.map((ongoing_activity, index) => (
        <div
          key={index}
          className="tips_box"
          data-url={`${ongoing_activity.buy_url}&ref=home_group`}
          onClick={props.onMiniProgramClick}
        >
          <div className="title">{ongoing_activity.name}</div>
          <div className="buy_status_box">
            <div className="left_buy_tips">
              <div className="remainder_person">
                还差
                <span>{ongoing_activity.remaining_shopping_group_count}人</span>
                {ongoing_activity.sale_mode == "invitation_fission"
                  ? "报名成功"
                  : "拼成"}
                {/* {`即可免费领取`}
              {`即可以¥${19}购买`} */}
              </div>
              <CountDownTime
                endTime={new Date(ongoing_activity.groupon_endtime)}
                overText="活动已结束"
                onEnd={() => {}}
              >
                {({ d, h, m, s }) => {
                  return (
                    <span>
                      剩余：
                      {`${d == "00" ? "" : `${d}天`}`}
                      {h}:{m}:{s}
                    </span>
                  );
                }}
              </CountDownTime>
            </div>
            <div className="right_invite_entrance">
              {new Date(systemTime) >=
              new Date(ongoing_activity.groupon_endtime)
                ? "查看"
                : `${
                    ongoing_activity.sale_mode == "invitation_fission"
                      ? "继续赠课"
                      : "继续拼团"
                  }`}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
});

export default BuyStatusTips;
