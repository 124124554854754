import CreateStore from "./CreateStore.js";

class ApplicationStore {
  init = () => {
    if (typeof this.reducer !== "function") {
      throw new Error("You Store must defined a function reducer");
    }
    if (typeof this.actionCreators !== "function") {
      throw new Error("You Store must defined a function actionCreators");
    }

    const build = (props) => {
      return new CreateStore(
        { ...(this.defaultStates || {}), ...props },
        this.reducer
      );
    };

    return {
      build,
      actions: this.actionCreators(),
      destroy: this.destroy,
    };
  };

  destroy = () => {
    if (this.store) {
      this.store.destroy();
      this.states = null;
    }
  };
}

export default ApplicationStore;
