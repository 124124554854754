import React, { useMemo } from "react";

const Image = ({
  className = "",
  url,
  isEnableWebP = true,
  alt = "",
  isLazy = true,
}) => {
  const imgAttrs = useMemo(() => {
    const props = {
      alt,
    };
    if (isLazy) {
      props["data-src"] = url;
    } else {
      props.src = url;
    }
    return props;
  }, [alt, isLazy, url]);

  const sourceAttr = useMemo(() => {
    const props = {};
    if (isLazy) {
      props["data-srcset"] = `${url}?imageMogr2/format/webp`;
    } else {
      props.srcSet = `${url}?imageMogr2/format/webp`;
    }
    return props;
  }, [isLazy, url]);

  if (!isEnableWebP) return <img {...imgAttrs} className={className} />;

  return (
    <picture className={className}>
      <source type="image/webp" {...sourceAttr} />
      <img {...imgAttrs} className={className} />
    </picture>
  );
};

export default Image;
