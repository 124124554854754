import { useContext } from "react";
import { MyDispatchContext, MyStatesContext } from "./MyContext.js";

export const useDispatchContext = () => {
  const contextValue = useContext(MyDispatchContext);
  return contextValue;
};

export const useStatesContext = () => {
  const contextValue = useContext(MyStatesContext);
  return contextValue;
};
