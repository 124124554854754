/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { request, csrfHeaders } from "../../utils/request";
import { debounce } from "../../utils/helpers";
import { ToastManager } from "../../utils/Toast";
import Raven from "raven-js";
import new_tips_alert from "study_package_activity/new_tips_alert.png";
import { ButtonFactory } from "../../../components/questionnaire/Questionnaire";

import "./ChooseGradeModal.scss";
import "../../questionnaire/QuestionnaireActivity.scss";

class ChooseGradeModal extends Component {
  constructor(props) {
    super(props);
    console.log("ChooseGradeModal: ", props);

    const { grades } = this.props;
    // const preSchool = []; // 入园前
    const nurserySchool = []; // 幼儿园
    const primarySchool = []; // 小学
    // const juniorSchool = []; // 初中
    const othersSchool = []; // 其他
    const lastGrades = [];

    grades.forEach((item) => {
      // this.getTitleId(item, "入园前", preSchool);
      this.getTitleId(item, "幼儿园", nurserySchool);
      this.getTitleId(item, "小学", primarySchool);
      // this.getTitleId(item, "初中", juniorSchool);
      this.getTitleId(item, "其他", othersSchool);
    });

    lastGrades.push(
      // { category: preSchool[0].category, schools: preSchool },
      { category: nurserySchool[0].category, schools: nurserySchool },
      { category: primarySchool[0].category, schools: primarySchool },
      // { category: juniorSchool[0].category, schools: juniorSchool },
      { category: othersSchool[0].category, schools: othersSchool }
    );

    console.log("lastGrades: ", lastGrades);

    this.state = {
      schoolId: -1,
      gradeIndex: -1,
      textLight: false,
      lastGrades,
    };
  }

  getTitleId = (item, category_name, needArray) => {
    // eslint-disable-next-line no-unused-expressions
    item.category == category_name
      ? needArray.push({
          category: item.category,
          ch_title: item.zh_title,
          id: item.id,
        })
      : "";
  };

  handleGradeRequest = () => {
    if (!this.state.textLight) {
      alert("请选择孩子所在年级");
      return;
    }

    const params = {
      user_baby: {
        baby_attributes: {
          grade: Number(this.state.schoolId),
        },
      },
    };
    ToastManager.showLoading("请稍等...");
    request({
      url: this.props.createUserBabyGrade,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        // let data = resp.data;
        // window.location.href = data.redirect_url;
        window.location.reload(true);
      })
      .catch((err) => {
        Raven.captureException(err);
      });
  };

  handleGradeSchool = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    this.setState({
      schoolId: id,
    });
  };

  handleClickIdx = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    this.setState({
      gradeIndex: index,
      textLight: true,
    });
  };

  handleCloseGradeModal = (e) => {
    this.props.chooseGradeModalStatus();
  };

  render() {
    const { schoolId, gradeIndex, textLight, lastGrades } = this.state;

    // const chooseGradeHeadTitle = () => {
    //   return (
    //     <div className="choose_grade_title">
    //       <div className="title">请选择孩子的年级</div>
    //     </div>
    //   );
    // };

    const SubmitButton = ButtonFactory({
      action: debounce(this.handleGradeRequest),
      text: "提交",
      extendedStyle: "submit_grade",
    });

    return (
      <div className="comp_questionnaire_activity">
        <div className="question_ground">
          <div className={`question_body_box`}>
            <div
              className="modal_close"
              onClick={this.handleCloseGradeModal}
            ></div>
            <div
              className="question_render_body"
              // style={isEmpty(recommendations) ? { position: "initial" } : {}}
            >
              <div className="title_header">
                <img src={new_tips_alert} alt="" />
              </div>
              <ul className="last_grade_ul">
                {lastGrades.map((item, index) => (
                  <li
                    key={index}
                    data-index={index}
                    onClick={this.handleClickIdx}
                  >
                    {item.category.length > 2 ? (
                      <div
                        className={`left_grade ${
                          gradeIndex == index ? "text_light" : ""
                        }`}
                      >
                        {item.category}
                      </div>
                    ) : (
                      <div
                        className={`left_grade ${
                          gradeIndex == index ? "text_light" : ""
                        }`}
                      >
                        <span>{item.category.slice(0, 1)}</span>
                        <span>{item.category.slice(1, 2)}</span>
                      </div>
                    )}
                    <div className="age_grade_list">
                      {item.schools.map((school) => (
                        <div
                          key={school.id}
                          className={`list_item ${
                            schoolId == school.id ? "hight_light" : ""
                          }`}
                          data-id={school.id}
                          onClick={this.handleGradeSchool}
                          style={{
                            width: school.ch_title.length > 5 ? "60%" : null,
                            marginBottom:
                              school.ch_title.length > 5 ? "15px" : null,
                          }}
                        >
                          {school.ch_title}
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
      // <div className="comp_choose_grade_modal">
      //   <div className="choose_grade_modal_box">
      //     <div className="close_modal" onClick={this.handleCloseGradeModal}>
      //       &times;
      //     </div>
      //     {chooseGradeHeadTitle()}
      //     <ul>
      //       {lastGrades.map((item, index) => (
      //         <li key={index} data-index={index} onClick={this.handleClickIdx}>
      //           {item.category.length > 2 ? (
      //             <div
      //               className={`left_grade ${
      //                 gradeIndex == index ? "text_light" : ""
      //               }`}
      //             >
      //               {item.category}
      //             </div>
      //           ) : (
      //             <div
      //               className={`left_grade ${
      //                 gradeIndex == index ? "text_light" : ""
      //               }`}
      //             >
      //               <span>{item.category.slice(0, 1)}</span>
      //               <span>{item.category.slice(1, 2)}</span>
      //             </div>
      //           )}
      //           <div className="age_grade_list">
      //             {item.schools.map(school => (
      //               <div
      //                 key={school.id}
      //                 className={`list_item ${
      //                   schoolId == school.id ? "hight_light" : ""
      //                 }`}
      //                 data-id={school.id}
      //                 onClick={this.handleGradeSchool}
      //                 style={{
      //                   width: school.ch_title.length > 5 ? "60%" : null,
      //                   marginBottom: school.ch_title.length > 5 ? "15px" : null
      //                 }}
      //               >
      //                 {school.ch_title}
      //               </div>
      //             ))}
      //           </div>
      //         </li>
      //       ))}
      //     </ul>
      //     <div className="warn_text">选错年级，会导致系统推荐不合适的课程</div>
      //     <button
      //       className={`${textLight ? "button_light" : ""}`}
      //       onClick={debounce(this.handleGradeRequest)}
      //     >
      //       确定
      //     </button>
      //   </div>
      // </div>
    );
  }
}

export default ChooseGradeModal;
