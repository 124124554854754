/* eslint-disable react/prop-types */
import React, { useReducer, useMemo, useEffect } from "react";
import { useStatesContext } from "./useContext.js";
import { MyStatesContext, MyDispatchContext } from "./MyContext.js";

/**
 * nested using MyProvider may occur problems. This is the next step to do
 */
const MyProvider = ({ children, store }) => {
  const tryUseStates = useStatesContext();
  if (tryUseStates) {
    throw new Error(
      "Your are nested using MyProvider. Please merge your usage into single MyProvider"
    );
  }

  const [myStates, myDispatch] = useReducer(store.reducer, store.getStates());

  if (myStates !== store.getStates()) {
    store.setStates(myStates);
    store.emit();
  }
  const dispatch = useMemo(() => ({ myDispatch }), [myDispatch]);

  useEffect(() => {
    return () => {
      store.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyStatesContext.Provider value={store}>
      <MyDispatchContext.Provider value={dispatch}>
        {children}
      </MyDispatchContext.Provider>
    </MyStatesContext.Provider>
  );
};

export default MyProvider;
