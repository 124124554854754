import {
  useRef,
  useReducer,
  useLayoutEffect,
  useCallback,
  useEffect,
} from "react";
import { useStatesContext } from "./useContext.js";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const refEquality = (a, b) => a === b;

const useSelector = (selector) => {
  const store = useStatesContext();
  const [, forceRender] = useReducer((s) => s + 1, 0);
  const latestSubscriptionCallbackError = useRef();
  const latestSelector = useRef();
  const latestSelectedState = useRef();

  let selectedState;

  try {
    if (
      selector !== latestSelector.current ||
      latestSubscriptionCallbackError.current
    ) {
      selectedState = selector(store.getStates());
    } else {
      selectedState = latestSelectedState.current;
    }
  } catch (err) {
    let errorMessage = `An error occured while selecting the store state: ${err.message}.`;

    if (latestSubscriptionCallbackError.current) {
      errorMessage += `\nThe error may be correlated with this previous error:\n${latestSubscriptionCallbackError.current.stack}\n\nOriginal stack trace:`;
    }
    console.error(errorMessage);
  }

  useIsomorphicLayoutEffect(() => {
    latestSelector.current = selector;
    latestSelectedState.current = selectedState;
    latestSubscriptionCallbackError.current = undefined;
  });

  const checkUpdate = useCallback(() => {
    try {
      const newSelectedState = latestSelector.current(store.getStates());
      if (refEquality(newSelectedState, latestSelectedState.current)) {
        return;
      }
      latestSelectedState.current = newSelectedState;
    } catch (err) {
      console.error(err);
      latestSubscriptionCallbackError.current = err;
    }
    forceRender({});
  }, [store]);

  useIsomorphicLayoutEffect(() => {
    store.subscribe(checkUpdate);
    checkUpdate();
  }, [checkUpdate, store]);

  return selectedState;
};

export default useSelector;
